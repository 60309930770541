// extracted by mini-css-extract-plugin
export var breadcrumb = "pageLta-module--breadcrumb--aZ12o";
export var card_project = "pageLta-module--card_project--k+VwZ";
export var content_wrapper = "pageLta-module--content_wrapper--f7ky0";
export var heading = "pageLta-module--heading--gvwsu";
export var heading_desc = "pageLta-module--heading_desc--POU1q";
export var project_container = "pageLta-module--project_container--8bVNK";
export var project_header = "pageLta-module--project_header--w5Pro";
export var project_meta_info = "pageLta-module--project_meta_info--kXZDV";
export var project_meta_wrapper = "pageLta-module--project_meta_wrapper--C+2xQ";
export var swiper_container = "pageLta-module--swiper_container--uv440";
export var swiper_wrapper = "pageLta-module--swiper_wrapper--LwR5M";