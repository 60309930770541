import React, { useRef, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import { Pagination } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import * as styles from "../../styles/pages/pageLta.module.scss";
import "swiper/scss";
import "swiper/css/navigation";
import "swiper/scss/pagination";

// https://preview.themeforest.net/item/wyatt-creative-portfolio-theme/full_screen_preview/38508842?_ga=2.230036889.40908565.1668410921-653230213.1668410921

import ltaBCM from "../../images/work/lta/ltaBCM.png";
import ltaData from "../../images/work/lta/ltaData.png";
import ltaSign from "../../images/work/lta/ltaSign.png";
import ltaUpload from "../../images/work/lta/ltaUpload.png";

export default function LandTransportAuthority() {
  const [project, setProject] = useState("0");

  return (
    <div className={styles.content_wrapper}>
      <div className={styles.breadcrumb}>
        <PageBreadCrumb />
      </div>
      <div className={styles.heading}>
        <h1>Land Transport Authority</h1>
        <p>2019 - 2021</p>
        <p className={styles.heading_desc}>
          Pariatur ullamco consequat adipisicing Lorem velit voluptate ipsum duis excepteur eu
          voluptate commodo consectetur.
        </p>
      </div>
      <Slider actions={{ setProject }} />
      <ProjectContent project={project} />
    </div>
  );
}

const PageBreadCrumb = () => {
  const crumbs = {
    trail: [
      {
        name: "All Works",
        url: "/work",
      },
    ],
    current: {
      name: "Land Transport Authority",
    },
  };

  return (
    <div className={styles.breadcrumb}>
      <Breadcrumb config={crumbs} />
    </div>
  );
};

const Slider = ({ actions }) => {
  const swiperRef = useRef();

  const breakpoints = {
    320: {
      slidesPerView: 2,
      spaceBetween: 100,
      pagination: true,
      allowTouchMove: true,
    },
    640: {
      pagination: true,
    },
  };

  function updateProject() {
    const newIndex = swiperRef.current?.activeIndex;
    actions.setProject(newIndex);
  }

  return (
    <div className={styles.swiper_container}>
      <div className={styles.swiper_wrapper}>
        <Swiper
          modules={[Pagination]}
          breakpoints={breakpoints}
          spaceBetween={240}
          slidesPerView={2}
          centeredSlides={true}
          allowTouchMove={true}
          pagination={true}
          slideToClickedSlide={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={() => updateProject()}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          <SwiperSlide>
            <CardProject image={ltaData} />
          </SwiperSlide>
          <SwiperSlide>
            <CardProject image={ltaBCM} />
          </SwiperSlide>
          <SwiperSlide>
            <CardProject image={ltaSign} />
          </SwiperSlide>
          <SwiperSlide>
            <CardProject image={ltaUpload} />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

const CardProject = ({ image }) => {
  return (
    <div className={`card__hover`}>
      <div className={`${styles.card_project}`}>
        <img src={image} alt="lta" />
      </div>
    </div>
  );
};

const ProjectContent = ({ project }) => {
  const title = {
    0: "Data Readiness Toolkit",
    1: "Bus Contract Management",
    2: "DSL Enforcement",
    3: "Upload Portal",
  };
  return (
    <div className={styles.project_container}>
      <div className={styles.project_header}>
        <h2>{title[project]}</h2>
        <p>
          A UIUX enhancement to improve the branding presence of and experience of uploading a
          toolkit
        </p>
      </div>

      <div className={styles.project_meta_wrapper}>
        <div>
          <div className={styles.project_meta_info}>
            <p>My Role</p>
            <p>UI/UX Designer</p>
          </div>
          <div className={styles.project_meta_info}>
            <p>My Contribution</p>
            <p>
              User Experience Design, User Interface Design, Prototyping, Design Advocacy, Design
              Ops
            </p>
          </div>
          <div className={styles.project_meta_info}>
            <p>About the Team</p>
            <p>Myself</p>
          </div>
        </div>

        <div>
          <div className={styles.project_meta_info}>
            <p>Year</p>
            <p>2019</p>
          </div>
          <div className={styles.project_meta_info}>
            <p>Platform</p>
            <p>Figma</p>
          </div>
        </div>
      </div>

      <div>
        <h3>The Problem</h3>
        <p>
          A UIUX enhancement to improve the branding presence of and experience of uploading a
          toolkit
        </p>
      </div>
    </div>
  );
};
